import "../Styles/HeaderIndex.css"
import Logo from "../Assets/logo/logo chez fanfan sans fond blanc.png"
import React from 'react';
import { useEffect } from 'react';
import { Link, useLocation} from "react-router-dom";


function Header() {
  const location = useLocation();

    const scrollToMenuSection = () => {
      const menuSection = document.getElementById('MenuID');
      if (menuSection) {
        setTimeout(() => {
          menuSection.scrollIntoView({ behavior: 'smooth' });
        }, 100); 
      }
    };

    useEffect(() => {
      // Réinitialise les animations à chaque changement de page
      const links = document.querySelectorAll('.Link');
      links.forEach((link) => {
        link.classList.remove('animate');
        // Forcer le reflow pour que la suppression prenne effet avant de réappliquer l'animation
        void link.offsetWidth;
        link.classList.add('animate');
      });
    }, [location]);
  
    return (
      <div className="Body">
        <div className="HeaderBanner">
          <div className="Image" alt="Background_Food"></div>
          <div className="LogoBanner">
            <img src={Logo} className="Logo link-5" alt="Logo" />
            <div className="SpaceBanner">
              <nav className="NavBanner">
                <Link to="/" className="Link link-1">
                  Accueil
                </Link >
                <a
                  href="/#MenuID"
                  onClick={scrollToMenuSection}
                  className="Link link-2"
                >
                  Menu
                </a>
                <Link to="/Traiteur" className="Link link-3">
                  Traiteur
                </Link>
                <Link to="/Contact" className="Link link-4">
                  Contact
                </Link>
              </nav>
            </div>
          </div>
        </div>
      </div>
    );
  }
  
  export default Header;
  