import '..//Styles/Footer.css'
import Logo from '..//Assets/logo/logo chez fanfan sans fond blanc.png'
import { Link } from 'react-router-dom';
import React from 'react';

function Footer (){
    const touchClick = () => {
        window.location.reload();
        window.scrollTo(0,0)
    } 
    return (
        <div className="footerBanner">
            <div className='footerElement'>
            <Link to="/" onClick={touchClick} className='logo_space'>
                <img src={Logo} alt="Logo fin" className='picture'></img>
            </Link>
                <div className='textFooter'> 
                    <div className='infotop'>Ouvert du <b>mardi midi au samedi soir </b>
                        <div className='infophone'>- 05 61 42 53 96 -</div>
                        <div className='infocenter'>35 bis Route de Toulouse, 31700 Cornebarrieu</div>
                        <div className=' infoend'>© 2024 ChezFanFan. All rights reserved </div> 
                        </div>
                    </div>
            </div>
        </div>

    )
}

export default Footer