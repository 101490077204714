import data from "../data.json";
import { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { faWineGlass } from '@fortawesome/free-solid-svg-icons';

function Beverage() {
  const [showWines, setShowWines] = useState(false);

  const rose = data.beverages.roses;
  const blanc = data.beverages.blancs;
  const rouge = data.beverages.rouges;
  const champagne = data.beverages.champagnes;

  const toggleWines = () => {
    setShowWines((prevShowWines) => !prevShowWines);
  };

  return (
    <div className="Formule2">
      <div className="wine" onClick={toggleWines}>
        <div className="Title_Text_Soir">Découvrez nos Vins</div>
        {/* <FontAwesomeIcon icon={faWineGlass} className="icon_glass"/> */}
      </div>

      {showWines && (
        <div className="appear_wine">
          <div className="Title_Text_Soir">Les Vins</div>

          <div className="Menu_space_Soir bever_phone">
            <div className="Menudusoir bever_space">
              <h2>Nos Rouges</h2>
              <div className="beverages">
                {rouge.map((item, index) => (
                  <div key={index}>
                    <div className="style_space">
                      <div className="style">{item.nom}</div>
                      <div className="Price">{item.prix}</div>
                    </div>
                    <div className="separator"></div>
                  </div>
                ))}
              </div>

              <h2>Nos Rosés</h2>
              <div className="beverages">
                {rose.map((item, index) => (
                  <div key={index}>
                    <div className="style_space">
                      <div className="style">{item.nom}</div>
                      <div className="Price">{item.prix}</div>
                    </div>
                    <div className="separator"></div>
                  </div>
                ))}
              </div>
            </div>

            <div className="Bever"></div>

            <div className="Menudusoir bever_space">
              <h2>Nos Blancs</h2>
              <div className="beverages">
                {blanc.map((item, index) => (
                  <div key={index}>
                    <div className="style_space">
                      <div className="style">{item.nom}</div>
                      <div className="Price">{item.prix}</div>
                    </div>
                    <div className="separator"></div>
                  </div>
                ))}
              </div>

              <h1>Les Champagnes</h1>
              <div className="beverages">
                {champagne.map((item, index) => (
                  <div key={index}>
                    <div className="style_space">
                      <div className="style">{item.nom}</div>
                      <div className="Price">{item.prix}</div>
                    </div>
                    <div className="separator"></div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="Menu_Offres_Text2 space">
            * Possibilité de consommer au verre, pour cela, demander au
            serveur/se *
          </div>
          <div className="Menu_Offres_Text2 space">* Cocktails et Mocktails également disponibles *</div>
        </div>
        
      )}
         <div className="Menu_CTA_Soir">Réservez au <b><FontAwesomeIcon icon={faPhone} /> 05 61 42 53 96</b></div>
    </div>
  );
}

export default Beverage;
