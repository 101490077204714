import "../Styles/MenuduSoir.css"
import "../Styles/Menu.css"
import Beverage from "./Boissons";

function MenuduSoir(props){

    return (
        <div className="Module_Menu">
            <div className="TextMenu">{props.title}
            </div>
            <div className="Backgd_Img2">
                <div className="Formule2">
                    <div className="Title_Text_Soir" id="LaCarte">{props.text}</div>
                        <div className="Menu_space_Soir">
                            <div className="Menudusoir">
                                <h3>{props.titlentree}</h3>
                                <div className="Menu_Text2_Soir">
                                    <ul className="Liste_Soir">
                                        <li><span>Foie gras de canard mi-cuit, confit d'oignons, </span><div className="Price">15€</div></li>
                                        <li><span>Cœurs de canard persillés, </span><div className="Price">10€</div></li>
                                        <li><span>Gambas en feuilles de brick, huile de basilic, mayonnaise paprika </span><div className="Price">12€</div></li>
                                        <li><span>Lamelle d'encornet carbonara à notre façon et chips de Lard </span><div className="Price">12€</div></li>
                                        <li><span>Salade fanfan, <b style={{fontSize: "25px"}}></b></span><div className="Price">17€</div></li>
                                        <li><span>Salade Gasconne </span><div className="Price">17€</div></li>
                                        <li><span>Planche de Jambon </span><div className="Price">15€</div></li>
                                        <li><span>Bol de saucisses sèches. </span><div className="Price">4€</div></li>
                                    </ul>
                                </div>
                                <h3>{props.titleviande}</h3>
                                <div className="Menu_Text2_Soir">
                                    <ul className="Liste_Soir">
                                        <li><span>Magret de canard du Sud Ouest, </span><div className="Price">24€</div></li>
                                        <li><span>Côte de cochon fermier plein air label rouge, <b style={{fontSize: "25px"}}></b></span><div className="Price">24€</div></li>
                                        <li><span>Entrecôte Simmental </span><div className="Price">28€</div></li>
                                        <li><span>Côte de boeuf Simmental </span><div className="Price">75€</div></li>
                                        <li><span>Filet de boeuf Simmental </span><div className="Price"> 28€</div></li>
                                        <li><span>Côtes d'agneau filet label rouge/pays d'oc, </span><div className="Price">22€</div></li>
                                        <li><span>Souris d'agneau cuite basse température et braisée, sauce Curry Madras </span><div className="Price">22€</div></li>
                                        <li><span>Confit de canard du Sud Ouest. </span><div className="Price">16€</div></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="Border_Text_Soir"></div>
                            <div className="Menudusoir">
                                <h3>{props.titleplat}</h3>
                                <div className="Menu_Text2_Soir">
                                    <ul className="Liste_Soir">
                                        <li><span>Maigre de ligne rôti, son risotto parmesan, huile verte et ses petits légumes sautés et chips de carottes</span><div className="Price">23€</div></li>
                                        <li><span>Ris de veau rissolés à la crème de morilles accompagnés de ses pommes de terre et pommes paille, </span><div className="Price">24€</div></li>
                                        <li><span>Ravioles de confit de canard du sud-ouest et son velouté de foie gras,</span><div className="Price">22€</div></li>
                                        <li><span>Cassoulet au confit de canard. </span><div className="Price">19€</div></li>

                                    </ul>
                                </div>
                                <h3>{props.titledessert}</h3>
                                <div className="Menu_Text2_Soir">
                                    <ul className="Liste_Soir">
                                    <li><span>Cœur coulant au chocolat, son crumble cacao et sa glace vanille, </span><div className="Price">8€</div></li>
                                    <li><span>Ris au lait enrobé de crème mascarpone, son caramel beurre salé et muësli maison </span><div className="Price">8€</div></li>
                                    <li><span>Soufflé citron et son sorbet agrumes, </span><div className="Price">8€</div></li>
                                    <li><span>Framboises et pistaches sous différentes structures et sa glace yaourt grec</span><div className="Price">8€</div></li>
                                    <li><span>Assiette de fromages, </span><div className="Price">7€</div></li>
                                    <li><span>Café liégeois, </span><div className="Price"> 7€</div></li>
                                    <li><span>Chocolat liégeois, </span><div className="Price">7€</div></li>
                                    <li><span>Colonel. </span><div className="Price">7€</div></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="Menu_Offres">
                            <span className="Title_Text_Soir">Les Menus</span>
                            <div className="Menu_Offres_Space">
                                <div className="Menu_Offres_Text">
                                    <ul className="menu">
                                        <li>
                                            <div className="Menu_Flex">
                                                <h3>3 PLATS AU CHOIX</h3>
                                                <div className="Price2">37€</div>
                                            </div>
                                        </li>
                                        <div className="Menu_Offres_Text2">
                                            <li>Composez votre Menu</li>
                                            <li><b>Entrée - Plat - Dessert</b></li>
                                            <li><b>Au Choix</b></li>
                                            <li>(supplément 2€ pour entrecôte et filet de boeuf)</li>
                                        </div>
                                    </ul>
                                </div>
                                <div className="Menu_Offres_Text">
                                    <ul className="menu">
                                        <li>
                                            <div className="Menu_Flex">
                                                <h3>4  PLATS AU CHOIX</h3>
                                                <div className="Price2">42€</div>
                                            </div>
                                        </li>
                                        <div className="Menu_Offres_Text2">
                                            <li>Pour les plus gourmands, nous vous proposons de composer votre menu avec </li>
                                            <li><b>2 entrées - 1 plat - 1 Dessert</b></li>
                                            <li>Ou</li>
                                            <li><b>1 entrée - 1 plat - 2 Desserts</b></li>
                                            <li>(supplément 2€ pour entrecôte et filet de boeuf)</li>
                                        </div>
                                    </ul>
                                </div>
                                <div className="Menu_Offres_Text">
                                    <ul className="menu">
                                        <li>
                                            <div className="Menu_Flex">
                                                <h3>MENU PITCHOUN</h3>
                                                <div className="Price2">10,50€</div>
                                            </div>
                                        </li>
                                        <div className="Menu_Offres_Text2">
                                            <li>Petite boisson</li>
                                            <li>Saucisse de Toulouse maison</li>
                                            <li>ou steak haché</li>
                                            <li>Accompagnement au Choix</li>
                                            <li>Glace artisanale</li>
                                        </div>
                                    </ul>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
            <Beverage/>
        </div>
    )
}

export default MenuduSoir